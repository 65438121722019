'use client';
import { Dispatch, SetStateAction } from 'react';

// import { Container } from './styles';
type InputProps = {
  name: string;
  type: string;
  placeholder: string;
  value?: string;
  change?: Dispatch<SetStateAction<any>>;
  changeValue?: boolean;
  blur?: Dispatch<SetStateAction<any>>;
  blurValue?: boolean;
  click?: () => void;
  label?: string;
  img?: string;
  horizontal?: boolean;
  className?: string;
  maxlength?: number;
  errors?: { [x: string]: any };
  touched?: { [x: string]: any };
};

const sanitazeErrorObject = (errors, name) => {
  if (name.includes('.')) {
    const names = name.split('.');
    let error: any = false;

    names.forEach((element) => {
      if (!error) {
        error = errors[element];
        return;
      }

      error = error[element];
    });

    return error;
  }
  return errors[name];
};
const input = ({
  value,
  change,
  click,
  label,
  name,
  type,
  placeholder,
  img,
  horizontal,
  maxlength,
  changeValue = true,
  className = '',
  errors,
  touched,
  ...props
}: InputProps) => {
  const marginTop = horizontal ? 'mt-8' : 'mt-0';

  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className={`bg-white items-stretch self-stretch justify-center border border-[color:var(--divider,#F2F4F7)] flex flex-col ${marginTop} pl-3 pr-9 py-2.5 rounded-lg border-solid max-md:max-w-full max-md:pr-5 w-full`}
        {...props}
      >
        <div className="text-gray-500 text-xs font-medium leading-3 tracking-normal whitespace-nowrap max-md:max-w-full">
          {label}
        </div>
        <div className="flex gap-2 max-md:flex-wrap">
          {img && (
            <img
              loading="lazy"
              src={img}
              className="shrink-0 w-6 aspect-square"
            />
          )}
          <div className="text-gray-900 text-sm font-medium leading-6 tracking-normal w-full max-md:max-w-full">
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              className="bg-transparent focus:border-transparent focus:outline-none focus:ring-0 w-full"
              maxLength={maxlength || 1000000000}
              onChange={(e) => {
                if (change) {
                  if (changeValue) {
                    change(e.target.value);
                    return;
                  }
                  change(e);
                }
              }}
              onBlur={(e) => {
                if (change) {
                  if (changeValue) {
                    change(e.target.value);
                    return;
                  }
                  change(e);
                }
              }}
              onClick={(e) => {
                if (click) {
                  click();
                }
              }}
              value={value}
            />
          </div>
        </div>
      </div>
      {errors &&
        touched &&
        sanitazeErrorObject(errors, name) &&
        sanitazeErrorObject(touched, name) && (
          <div className="text-red-600 flex flex-wrap">
            {sanitazeErrorObject(errors, name) &&
              sanitazeErrorObject(touched, name) &&
              sanitazeErrorObject(errors, name)}
          </div>
        )}
    </div>
  );
};

export default input;
