/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */

'use client';

import Input from '@components/input';
import { signIn } from 'next-auth/react';
import { useState } from 'react';

type AuthProps = {
  isMobile?: boolean;
};

export default function Auth({ isMobile = false }: AuthProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitAuth = async (e) => {
    e.preventDefault();
    if (loading || (email === '' && password === '')) {
      return;
    }

    setLoading(true);
    await signIn('credentials', {
      username: email,
      password,
    });
  };

  return (
    <form
      method="post"
      onSubmit={handleSubmitAuth}
      data-mobile={isMobile}
      className="justify-center items-start self-stretch data-[mobile=false]:flex data-[mobile=true]:hidden flex-col my-auto px-5 max-md:max-w-full max-md:flex max-md:mb-10 data-[mobile=true]:max-sm:flex data-[mobile=false]:max-sm:hidden gap-6 h-full"
    >
      <div className="self-stretch-white text-gray-900 text-2xl font-bold leading-7 max-md:max-w-full max-md:text-white">
        Log in
      </div>

      <Input
        label="Email"
        type="email"
        name="username"
        placeholder="john.doe@email.com"
        change={setEmail}
        value={email}
        className='w-full'
      />

      <Input
        label="Password"
        type="password"
        name="password"
        placeholder="******"
        change={setPassword}
        value={password}
        className='w-full'
      />

      <button
        type="submit"
        data-enable={email !== '' && password !== ''}
        className="text-white text-base text-center font-semibold leading-7 whitespace-nowrap justify-center items-center self-stretch shadow-sm bg-indigo-500 px-5 py-2 rounded-xl max-md:max-w-full data-[enable=false]:bg-gray-300"
      >
        {loading && 'Log in...'}
        {!loading && 'Log in'}
      </button>
    </form>
  );
}
